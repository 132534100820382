import React, { useEffect } from 'react';
import { useWebMidi } from './hooks/useWebMidi';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './theme';
import { useAppStore, useSettingsStore } from './store';
import { GlobalStyle } from './GlobalStyle';
import Main from './components/Main';
import Dialogs from './components/Dialogs/Dialogs';
import { isDevelop, isTouch, getBrowser } from './utils/system';

export default function App() {
  const light = useSettingsStore((state) => state.light);
  const system = useAppStore((state) => state.system);
  const setSystem = useAppStore((state) => state.setSystem);

  /* eslint-disable */
  useEffect(() => {
    setSystem({
      isTouch: isTouch(),
      isDevelop: isDevelop(),
    });

    if (system.isTouch) setShowTooltips(false);
  }, []);

  let browser = getBrowser();

  if (browser !== 'Safari') {
    useWebMidi();
  }

  return (
    <div className='App'>
      <ThemeProvider theme={light ? lightTheme : darkTheme}>
        <GlobalStyle />
        <Main />
        <Dialogs />
      </ThemeProvider>
    </div>
  );
}
