import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  max-width: var(--maxWidth);
  margin: 0 auto 50px auto;
  padding: 6px 24px;
  padding-bottom: 144px;

  @media (max-width: ${({ theme }) => theme.break.horiz.sm}) {
    padding: 6px 12px;
  }

  h1 {
    color: ${({ theme }) => theme.color.h1};
    font-size: 1.2rem;
    user-select: none;

    @media (max-width: ${({ theme }) => theme.break.horiz.sm}) {
      font-size: 1rem;
    }
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 1rem;

  @media (max-width: ${({ theme }) => theme.break.horiz.sm}) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 0.5rem;
  }
`;
