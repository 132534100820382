import { create } from 'zustand';
import { persist } from 'zustand/middleware';

let appStore = (set) => ({
  system: {
    isTouch: false,
    isMobile: false,
    isDevelop: false,
  },
  setSystem: (input) => set({ system: input }),

  katMsg: {
    cmd: undefined,
    data: undefined,
  },
  setKatMsg: (input) => set({ katMsg: input }),

  preampType: undefined,
  setPreampType: (input) => set({ preampType: input }),

  bright: undefined,
  setBright: (input) => set({ bright: input }),

  solo: undefined,
  setSolo: (input) => set({ solo: input }),

  booster: undefined,
  setBooster: (input) => set({ booster: input }),

  mod: undefined,
  setMod: (input) => set({ mod: input }),

  fx: undefined,
  setFx: (input) => set({ fx: input }),

  delay: undefined,
  setDelay: (input) => set({ delay: input }),

  reverb: undefined,
  setReverb: (input) => set({ reverb: input }),

  channel: undefined,
  setChannel: (input) => set({ channel: input }),

  midiAccess: undefined,
  setMidiAccess: (input) => set({ midiAccess: input }),

  msgDialog: {
    show: false,
    title: '',
    content: null,
    footer: '',
  },
  setMsgDialog: (input) => set({ msgDialog: input }),

  showDialog: {
    info: false,
    hotkeys: false,
    midi: false,
  },
  setShowDialog: (input) => set({ showDialog: input }),

  midi: {
    input: undefined,
    output: undefined,
  },
  setMidi: (input) => set((state) => ({ midi: input })),
});

let settingsStore = (set) => ({
  light: true,
  toggleLight: () => set((state) => ({ light: !state.light })),

  compactCard: false,
  toggleCompact: () => set((state) => ({ compactCard: !state.compactCard })),

  midiPortName: undefined,
  setMidiPortName: (input) => set({ midiPortName: input }),
});

// Persist settings store
settingsStore = persist(settingsStore, { name: 'kato_settings' });

export const useAppStore = create(appStore);
export const useSettingsStore = create(settingsStore);
