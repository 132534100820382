import React from 'react';
import { useAppStore, useSettingsStore } from '../store';
import { Card, Grid, Header, Toolbar } from './';
import { listAmps } from '../utils/amps';
import { useKeyPress } from '../hooks/useKeyPress';
import CMDS from '../utils/cmds';

const Main = ({ ...props }) => {
  const msgDialog = useAppStore((state) => state.msgDialog);
  const showDialog = useAppStore((state) => state.showDialog);
  const setShowDialog = useAppStore((state) => state.setShowDialog);
  const setMsgDialog = useAppStore((state) => state.setMsgDialog);
  const toggleLight = useSettingsStore((state) => state.toggleLight);
  const preampType = useAppStore((state) => state.preampType);
  const setKatMsg = useAppStore((state) => state.setKatMsg);
  const bright = useAppStore((state) => state.bright);
  const solo = useAppStore((state) => state.solo);
  const booster = useAppStore((state) => state.booster);
  const mod = useAppStore((state) => state.mod);
  const fx = useAppStore((state) => state.fx);
  const delay = useAppStore((state) => state.delay);
  const reverb = useAppStore((state) => state.reverb);

  var modalActive = msgDialog.show;

  for (const item in showDialog) {
    modalActive |= showDialog[item];
  }

  function onKeyPress(event) {
    if (
      this !== event.target &&
      (/textarea|select/i.test(event.target.nodeName) ||
        event.target.type === 'text')
    ) {
      return;
    }

    if (event.key < 9 && event.key >= 0) {
      setKatMsg({
        cmd: CMDS.SET_CHANNEL,
        data: Number(event.key),
      });

      return;
    }

    switch (event.key) {
      case 'b':
        setKatMsg({
          cmd: CMDS.SET_FX_BOOSTER,
          data: !booster,
        });
        break;
      case 'd':
        setKatMsg({
          cmd: CMDS.SET_FX_DELAY,
          data: !delay,
        });
        break;
      case 'f':
        setKatMsg({
          cmd: CMDS.SET_FX_FX,
          data: !fx,
        });
        break;
      case 'h':
        setShowDialog({ hotkeys: true });
        break;
      case 'i':
        setShowDialog({ info: true });
        break;
      case 'm':
        setKatMsg({
          cmd: CMDS.SET_FX_MOD,
          data: !mod,
        });
        break;
      case 'p':
        setKatMsg({
          cmd: CMDS.SET_PREAMP_BRIGHT,
          data: !bright,
        });
        break;
      case 'l':
        toggleLight();
        break;
      case 'r':
        setKatMsg({
          cmd: CMDS.SET_FX_REVERB,
          data: !reverb,
        });
        break;
      case 's':
        setKatMsg({
          cmd: CMDS.SET_PREAMP_SOLO,
          data: !solo,
        });
        break;
      case 'x':
        setShowDialog({ midi: true });
        break;
      case 'Escape':
      case 'Enter':
        setShowDialog({ info: false });
        setShowDialog({ hotkeys: false });
        setMsgDialog({ show: false });
        break;
      default:
    }
  }

  // prettier-ignore
  useKeyPress(
    ['0', '1', '2', '3', '4', '5','6', '7', '8',
     'b', 'd', 'f', 'h', 'i', 'k', 'l', 'm', 'p', 'r', 's', 'x',
     'Enter', 'Escape',],
    true,
    onKeyPress
  );

  return (
    <>
      <Header {...props} />
      <Toolbar />
      <Grid modalActive={modalActive}>
        {listAmps.map((amp) => {
          return (
            <Card
              amp={amp}
              key={amp.id}
              active={preampType === amp.id ? 1 : 0}
              compactCard={true}
            />
          );
        })}
      </Grid>
    </>
  );
};

export default Main;
