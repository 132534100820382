export const createChecksum = (data) => {
  var sum = 0;
  var msg = [];
  var checkSum;

  data.forEach((item) => {
    sum = (sum + item) & 0x7f;
  });

  checkSum = (128 - sum) & 0x7f;

  msg = msg.concat(data);
  msg = msg.concat(checkSum);

  return msg;
};
