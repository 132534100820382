import styled from 'styled-components';

export const KatIcon = styled.img`
  height: 40px;
  display: block;
  margin: 0 auto 0 auto;
  animation: wobble 0.5s linear 0.5s 1;

  @keyframes wobble {
    from,
    to {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(0.9, 1.1);
    }
    50% {
      transform: scale(1.1, 0.9);
    }
    75% {
      transform: scale(0.95, 1.05);
    }
  }
`;

export const Icons = styled.div`
  margin: 24px 0px 0px auto;
  width: fit-content;

  .a-mail {
    color: var(--katGrey);
  }

  .icon {
    margin-right: 0.7rem;
    font-size: 1.3rem;
    color: var(--katGrey);
    transition: color 0.25s;

    &:hover {
      color: var(--katBlue);
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  margin-top: 40px;
`;

export const Button = styled.button`
  background: ${(props) => (props.focus ? 'var(--katBlue)' : 'white')};
  color: ${(props) => (props.focus ? 'white' : 'var(--katGrey)')};
  border: ${(props) => (props.focus ? 'none' : '1px solid var(--katGrey)')};
  border-radius: 4px;
  width: 84px;
  margin-left: 8px;
  padding: ${(props) => (props.focus ? '6px 0' : '4px 0')};
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    opacity: 0.9;
    border-color: var(--katGreyMedium);
  }
`;
