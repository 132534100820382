import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  background: var(--katGreyMedium);
  border-radius: 3px;
  z-index: 100;
`;

export const Content = styled.div`
  display: flex;
  height: 50px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

export const LabelBrand = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  color: white;
`;

export const LabelName = styled.div`
  font-size: 0.8rem;
  color: var(--katGreyLight);
`;

export const LogoImg = styled.img`
  width: 30px;
`;
