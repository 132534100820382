import Modal from '../Modal/Modal';
import MidiSelect from '../MidiSelect/MidiSelect';
import { useAppStore } from '../../store';
import { SiCodeberg } from 'react-icons/si';
import Icon from '../../images/kato_grey.png';
import gitInfo from '../../gitInfo.json';
import { IoGitCommitOutline } from 'react-icons/io5';
import { Icons, KatIcon } from './Dialogs.styles';

export default function Dialogs() {
  const msgDialog = useAppStore((state) => state.msgDialog);
  const setMsgDialog = useAppStore((state) => state.setMsgDialog);

  const showDialog = useAppStore((state) => state.showDialog);
  const setShowDialog = useAppStore((state) => state.setShowDialog);

  return (
    <>
      <Modal
        active={msgDialog.show}
        hideModal={() => setMsgDialog({ show: false })}
        title={msgDialog.title}
        footer={msgDialog.footer}
      >
        <>{msgDialog.content}</>
      </Modal>
      <Modal
        active={showDialog.info}
        hideModal={() => setShowDialog({ info: false })}
        title={'Info'}
        footer={
          <>
            <IoGitCommitOutline size={16} style={{ verticalAlign: '-24%' }} />
            <small>{gitInfo}</small>
          </>
        }
      >
        <KatIcon src={Icon} alt='kat-ico' />
        <Icons>
          <SiCodeberg
            className='icon'
            onClick={() => {
              window.open(
                'https://codeberg.org/haeckse/kato',
                '_blank',
                'noopener,noreferrer'
              );
            }}
          />
        </Icons>
      </Modal>
      <Modal
        active={showDialog.hotkeys}
        hideModal={() => setShowDialog({ hotkeys: false })}
        title={'Keyboard Hotkeys'}
        footer={<></>}
      >
        <p style={{ lineHeight: '2.5' }}>
          <p-tag>I</p-tag> Info | <p-tag>H</p-tag> Hotkeys | <p-tag>X</p-tag>{' '}
          MIDI
          <br />
          <p-tag>P</p-tag> Bright | <p-tag>S</p-tag> Solo
          <br />
          <p-tag>B</p-tag> Boost | <p-tag>M</p-tag> Mod | <p-tag>F</p-tag> FX |{' '}
          <p-tag>D</p-tag> Delay | <p-tag>R</p-tag> Reverb
          <br />
          <p-tag>0</p-tag> - <p-tag>8</p-tag>
          Channel
        </p>
      </Modal>
      <Modal
        active={showDialog.midi}
        hideModal={() => setShowDialog({ midi: false })}
        title={'Choose a MIDI Device'}
        footer={<></>}
      >
        <br />
        <MidiSelect />
        <br />
        <br />
      </Modal>
    </>
  );
}
