import React from 'react';
import { useAppStore } from '../../store';
import CMDS from '../../utils/cmds';

import { Wrapper, Content, Brand, Footer, Label, Led } from './Card.styles';

const Card = ({ ...props }) => {
  const labelKatLedColor = {
    Acoustic: 'cornflowerblue',
    Clean: 'mediumseagreen',
    Crunch: '#fa7d18',
    Lead: '#bc3737',
    Brown: 'purple',
  };

  const setKatMsg = useAppStore((state) => state.setKatMsg);

  return (
    <>
      <Wrapper
        $compactCard={props.compactCard}
        onClick={() => {
          setKatMsg({
            cmd: CMDS.SET_PREAMP_TYPE,
            data: props.amp.id,
          });
        }}
      >
        <Content $compactCard={props.compactCard}>
          <Brand>{props.amp.brand}</Brand>
          {props.amp.name}
          <Led $active={props.active} />
          <Footer $compactCard={props.compactCard}>
            {Object.keys(labelKatLedColor).map((key, idx) => {
              if (key === props.amp.katLed) {
                return (
                  <Label key={idx} color={labelKatLedColor[key]}>
                    {props.amp.katLed}
                  </Label>
                );
              }
              return null;
            })}

            <>{<Label>{props.amp.cat}</Label>}</>
            <>{<Label>{props.amp.sneaky ? 'Sneaky' : 'Default'}</Label>}</>
          </Footer>
        </Content>
      </Wrapper>
    </>
  );
};

export default Card;
