export const listAmps = [
  {
    id: 0x01,
    variation: false,
    sneaky: true,
    brand: 'BOSS',
    name: 'Full Range',
    cat: 'Advanced',
    katLed: 'Acoustic',
  },
  {
    id: 0x1c,
    variation: true,
    sneaky: true,
    brand: 'BOSS',
    name: 'Full Range Variation',
    cat: 'Advanced',
    katLed: 'Acoustic',
  },
  {
    id: 0x00,
    variation: false,
    sneaky: true,
    brand: 'BOSS',
    name: 'Natural Clean',
    cat: 'Advanced',
    katLed: 'Clean',
  },
  {
    id: 0x08,
    variation: true,
    sneaky: false,
    brand: 'Roland',
    name: 'JC-120',
    cat: 'Vintage',
    katLed: 'Clean',
  },
  {
    id: 0x1d,
    variation: true,
    sneaky: false,
    brand: 'Roland',
    name: 'JC-120 Variation',
    cat: 'Vintage',
    katLed: 'Clean',
  },
  {
    id: 0x09,
    variation: false,
    sneaky: true,
    brand: 'Fender',
    name: 'Twin Reverb',
    cat: 'Vintage',
    katLed: 'Clean',
  },
  {
    id: 0x0a,
    variation: false,
    sneaky: true,
    brand: 'Fender',
    name: 'Pro Reverb',
    cat: 'Vintage',
    katLed: 'Crunch',
  },
  {
    id: 0x02,
    variation: false,
    sneaky: true,
    brand: 'BOSS',
    name: 'Combo Crunch',
    cat: 'Advanced',
    katLed: 'Crunch',
  },
  {
    id: 0x03,
    variation: true,
    sneaky: true,
    brand: 'BOSS',
    name: 'Stack Crunch 4x12"',
    cat: 'Advanced',
    katLed: 'Crunch',
  },
  {
    id: 0x0b,
    variation: false,
    sneaky: false,
    brand: 'Fender',
    name: 'Bassman 4 x 10” Combo',
    cat: 'Vintage',
    katLed: 'Crunch',
  },
  {
    id: 0x1e,
    variation: true,
    sneaky: false,
    brand: 'Fender',
    name: 'Bassman 4 x 10” Combo Variation',
    cat: 'Vintage',
    katLed: 'Crunch',
  },
  {
    id: 0x0c,
    variation: false,
    sneaky: true,
    brand: 'Fender',
    name: 'Deluxe Reverb',
    cat: 'Vintage',
    katLed: 'Crunch',
  },
  {
    id: 0x0d,
    variation: false,
    sneaky: true,
    brand: 'VOX',
    name: 'VOX AC-30TB Drive-Sound',
    cat: 'Vintage',
    katLed: 'Crunch',
  },
  {
    id: 0x0e,
    variation: false,
    sneaky: true,
    brand: 'VOX',
    name: 'VOX AC-30TB Lead-Sound',
    cat: 'Vintage',
    katLed: 'Crunch',
  },
  {
    id: 0x0f,
    variation: false,
    sneaky: true,
    brand: 'Matchless',
    name: 'D/C-30 Drive-Sound',
    cat: 'Vintage',
    katLed: 'Crunch',
  },
  {
    id: 0x04,
    variation: false,
    sneaky: true,
    brand: 'BOSS',
    name: 'High Gain Stack',
    cat: 'Advanced',
    katLed: 'Lead',
  },
  {
    id: 0x06,
    variation: false,
    sneaky: true,
    brand: 'BOSS',
    name: 'Extreme Lead',
    cat: 'Advanced',
    katLed: 'Lead',
  },
  {
    id: 0x07,
    variation: false,
    sneaky: true,
    brand: 'BOSS',
    name: 'Core Metal',
    cat: 'Advanced',
    katLed: 'Lead',
  },
  {
    id: 0x10,
    variation: false,
    sneaky: true,
    brand: 'MESA/Boogie',
    name: 'Combo Lead-Sound',
    cat: 'Vintage',
    katLed: 'Lead',
  },
  {
    id: 0x11,
    variation: false,
    sneaky: true,
    brand: 'MESA/Boogie',
    name: 'Combo TS Drive-Sound',
    cat: 'Vintage',
    katLed: 'Lead',
  },
  {
    id: 0x12,
    variation: false,
    sneaky: true,
    brand: 'Marshall',
    name: '1959 Input I Treble',
    cat: 'Vintage',
    katLed: 'Lead',
  },
  {
    id: 0x13,
    variation: false,
    sneaky: true,
    brand: 'Marshall',
    name: '1959 Input I+II Body',
    cat: 'Vintage',
    katLed: 'Lead',
  },
  {
    id: 0x14,
    variation: false,
    sneaky: true,
    brand: 'MESA/Boogie',
    name: 'Dual-Rectifier Vintage',
    cat: 'Vintage',
    katLed: 'Lead',
  },
  {
    id: 0x15,
    variation: false,
    sneaky: true,
    brand: 'MESA/Boogie',
    name: 'Dual-Rectifier Modern',
    cat: 'Vintage',
    katLed: 'Lead',
  },
  {
    id: 0x16,
    variation: false,
    sneaky: true,
    brand: 'Hughes&Kettner',
    name: 'Triamp AMP3',
    cat: 'Vintage',
    katLed: 'Lead',
  },
  {
    id: 0x18,
    variation: false,
    sneaky: false,
    brand: 'Peavy',
    name: 'EVH 5150',
    cat: 'Vintage',
    katLed: 'Lead',
  },
  {
    id: 0x1f,
    variation: true,
    sneaky: false,
    brand: 'Peavy',
    name: 'EVH 5150 Variation',
    cat: 'Vintage',
    katLed: 'Lead',
  },
  {
    id: 0x17,
    variation: false,
    sneaky: false,
    brand: 'Soldano',
    name: 'SLO-100',
    cat: 'Vintage',
    katLed: 'Brown',
  },
  {
    id: 0x20,
    variation: true,
    sneaky: false,
    brand: 'Soldano',
    name: 'SLO-100 Variation',
    cat: 'Vintage',
    katLed: 'Brown',
  },
  {
    id: 0x05,
    variation: false,
    sneaky: true,
    brand: 'BOSS',
    name: 'Power Drive',
    cat: 'Advanced',
    katLed: 'Brown',
  },
];
