import { useAppStore, useSettingsStore } from '../../store';
import { IconContext } from 'react-icons';
import { Infobox } from '../';
import {
  Wrapper,
  Content,
  LogoImg,
  LogoWrap,
  InfoBoxWrap,
  ButtonWrap,
} from './Header.styles';
import Logo from '../../images/kato.png';
import { FiMoon, FiSun } from 'react-icons/fi';

const Header = ({ ...props }) => {
  const light = useSettingsStore((state) => state.light);
  const toggleLight = useSettingsStore((state) => state.toggleLight);
  const setShowDialog = useAppStore((state) => state.setShowDialog);

  return (
    <Wrapper>
      <Content>
        <LogoWrap>
          <LogoImg
            className='logo'
            src={Logo}
            alt='kato-logo'
            onClick={() => {
              setShowDialog({ info: true });
            }}
          />
        </LogoWrap>
        <InfoBoxWrap className='info'>
          <Infobox />
        </InfoBoxWrap>
        <ButtonWrap>
          <IconContext.Provider
            value={{
              size: '22px',
              style: {
                marginLeft: 'auto',
                marginRight: '24px',
                color: 'lightgrey',
              },
            }}
          >
            {light ? (
              <FiMoon
                cursor='pointer'
                onClick={() => {
                  toggleLight();
                }}
              />
            ) : (
              <FiSun
                cursor='pointer'
                onClick={() => {
                  toggleLight();
                }}
              />
            )}
          </IconContext.Provider>
        </ButtonWrap>
      </Content>
    </Wrapper>
  );
};

export default Header;
