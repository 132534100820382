import Katana from '../../images/katana_w.png';
import { useAppStore } from '../../store';
import { listAmps } from '../../utils/amps';

import {
  Wrapper,
  LogoImg,
  Content,
  LabelBrand,
  LabelName,
} from './Infobox.styles';

const Infobox = () => {
  const preampType = useAppStore((state) => state.preampType);

  return (
    <Wrapper>
      {preampType === undefined ? (
        <Content>
          <LogoImg className='logo' src={Katana} alt='katana-logo' />
        </Content>
      ) : (
        <>
          {listAmps.map((amp) => {
            if (amp.id === preampType) {
              return (
                <Content key={amp.id}>
                  <LabelBrand>{amp.brand}</LabelBrand>
                  <LabelName>{amp.name}</LabelName>
                </Content>
              );
            }
            return null;
          })}
        </>
      )}
    </Wrapper>
  );
};

export default Infobox;
