import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  height: auto;
  justify-content: space-between;
  background: var(--katGreyDark);
  padding: 6px 24px 6px;
`;

export const ButtonsLeft = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: flex-start;
`;

export const ButtonsCenter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: center;

  @media (max-width: 450px) {
    width: 100%;
    justify-content: flex-end;
    margin-top: 16px;
  }
`;

export const ButtonsRight = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: flex-end;
  margin: 8px 0;

  @media (max-width: 750px) {
    width: 100%;
    margin-top: 16px;
    margin-right: 3px;
  }
`;
