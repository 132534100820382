import styled from 'styled-components';

export const Wrapper = styled.div`
  background: var(--katGreyDark);
  z-index: 100;
  user-select: none;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  width: 100%;
`;

export const LogoWrap = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 30%;
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  width: 30%;
`;

export const InfoBoxWrap = styled.div`
  display: block;
  width: 40%;
`;

export const LogoImg = styled.img`
  display: block;
  cursor: pointer;
  margin-left: 24px;
  height: 32px;
`;
