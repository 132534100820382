export const EDIT_MODE = 'EDIT_MODE';
export const SET_CHANNEL = 'SET_CHANNEL';
export const GET_CHANNEL = 'GET_CHANNEL';
export const SET_PREAMP_TYPE = 'SET_PREAMP_TYPE';
export const SET_PREAMP_BRIGHT = 'SET_PREAMP_BRIGHT';
export const SET_PREAMP_SOLO = 'SET_PREAMP_SOLO';
export const GET_PREAMP_SOLO = 'GET_PREAMP_SOLO';
export const GET_PREAMP_DATA = 'GET_PREAMP_DATA';
export const SET_FX_BOOSTER = 'SET_FX_BOOSTER';
export const GET_FX_BOOSTER = 'GET_FX_BOOSTER';
export const SET_FX_MOD = 'SET_FX_MOD';
export const GET_FX_MOD = 'GET_FX_MOD';
export const SET_FX_FX = 'SET_FX_FX';
export const GET_FX_FX = 'GET_FX_FX';
export const SET_FX_DELAY = 'SET_FX_DELAY';
export const GET_FX_DELAY = 'GET_FX_DELAY';
export const SET_FX_REVERB = 'SET_FX_REVERB';
export const GET_FX_REVERB = 'GET_FX_REVERB';

const cmds = {
  EDIT_MODE,
  SET_CHANNEL,
  GET_CHANNEL,
  SET_PREAMP_TYPE,
  SET_PREAMP_BRIGHT,
  SET_PREAMP_SOLO,
  GET_PREAMP_SOLO,
  GET_PREAMP_DATA,
  SET_FX_BOOSTER,
  GET_FX_BOOSTER,
  SET_FX_MOD,
  GET_FX_MOD,
  SET_FX_FX,
  GET_FX_FX,
  SET_FX_DELAY,
  GET_FX_DELAY,
  SET_FX_REVERB,
  GET_FX_REVERB,
};

export default cmds;
