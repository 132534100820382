export function isDevelop() {
  if (process.env.NODE_ENV === 'development') {
    return true;
  }

  return false;
}

export function isMobile() {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (
    /windows phone/i.test(userAgent) ||
    /android/i.test(userAgent) ||
    (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
  ) {
    return true;
  }

  return false;
}

export function isTouch() {
  if ('ontouchstart' in document.documentElement) {
    return true;
  }

  return false;
}

export function getBrowser() {
  var sBrowser,
    sUsrAg = navigator.userAgent;

  if (sUsrAg.indexOf('Firefox') > -1) {
    sBrowser = 'Firefox';
  } else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
    sBrowser = 'Opera';
  } else if (sUsrAg.indexOf('Trident') > -1) {
    sBrowser = 'Internet Explorer';
  } else if (sUsrAg.indexOf('Edge') > -1) {
    sBrowser = 'Edge';
  } else if (sUsrAg.indexOf('Chrome') > -1) {
    sBrowser = 'Chrome';
  } else if (sUsrAg.indexOf('Safari') > -1) {
    sBrowser = 'Safari';
  } else {
    sBrowser = 'unknown';
  }

  return sBrowser;
}
