import { useSettingsStore, useAppStore } from '../../store';
import { Wrapper, SelectMidi } from './MidiSelect.styles';

export default function Dropdown() {
  const midiAccess = useAppStore((state) => state.midiAccess);
  const midiPortName = useSettingsStore((state) => state.midiPortName);
  const setMidiPortName = useSettingsStore((state) => state.setMidiPortName);
  const setShowDialog = useAppStore((state) => state.setShowDialog);

  function selectMidiChange(e) {
    setMidiPortName(e.target.value);
    setShowDialog({ selectMidi: false });
  }

  return (
    <Wrapper>
      <SelectMidi
        value={midiPortName || ''}
        onChange={selectMidiChange}
        id='midiSel'
      >
        <option value='' disabled>
          Select a MIDI device
        </option>
        {midiAccess && midiAccess.inputs.size > 0 ? (
          Array.from(midiAccess.inputs.values()).map((input, id) => (
            <option key={id} value={input.name}>
              {input.name}
            </option>
          ))
        ) : (
          <option value='' disabled>
            No devices available
          </option>
        )}
      </SelectMidi>
    </Wrapper>
  );
}
