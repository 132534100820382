import { Wrapper, Button, Led, Label } from './KatButton.styles';

const KatButton = ({ active, caption, onClick }) => {
  return (
    <Wrapper>
      <Button onClick={onClick}>
        <Led $active={active} />
      </Button>
      <Label>{caption}</Label>
    </Wrapper>
  );
};

export default KatButton;
