import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Content } from './Grid.styles';

const Grid = ({ header, modalActive, children }) => (
  <Wrapper style={{ filter: modalActive ? 'blur(4px)' : 'none' }}>
    <h1>{header}</h1>
    <Content>{children}</Content>
  </Wrapper>
);

Grid.propTypes = {
  header: PropTypes.string,
  children: PropTypes.array,
};

export default Grid;
