import { useAppStore } from '../../store';
import {
  Wrapper,
  ButtonsLeft,
  ButtonsCenter,
  ButtonsRight,
} from './Toolbar.styles';
import { KatButton } from '../';
import CMDS from '../../utils/cmds';

export default function Toolbar() {
  const setKatMsg = useAppStore((state) => state.setKatMsg);
  const bright = useAppStore((state) => state.bright);
  const solo = useAppStore((state) => state.solo);
  const booster = useAppStore((state) => state.booster);
  const mod = useAppStore((state) => state.mod);
  const fx = useAppStore((state) => state.fx);
  const delay = useAppStore((state) => state.delay);
  const reverb = useAppStore((state) => state.reverb);
  const channel = useAppStore((state) => state.channel);

  const buttonsCenter = {
    3: { caption: 'BOOST', value: booster, cmd: CMDS.SET_FX_BOOSTER },
    4: { caption: 'MOD', value: mod, cmd: CMDS.SET_FX_MOD },
    5: { caption: 'FX', value: fx, cmd: CMDS.SET_FX_FX },
    6: { caption: 'DELAY', value: delay, cmd: CMDS.SET_FX_DELAY },
    7: { caption: 'REVERB', value: reverb, cmd: CMDS.SET_FX_REVERB },
  };

  const chGroupA = {
    1: 'CH1',
    2: 'CH2',
    3: 'CH3',
    4: 'CH4',
  };

  const chGroupB = {
    5: 'CH5',
    6: 'CH6',
    7: 'CH7',
    8: 'CH8',
  };

  return (
    <Wrapper>
      <ButtonsLeft>
        <KatButton
          caption='BRIGHT'
          active={bright}
          onClick={() => {
            setKatMsg({
              cmd: CMDS.SET_PREAMP_BRIGHT,
              data: !bright,
            });
          }}
        />
        <KatButton
          caption='SOLO'
          active={solo}
          onClick={() => {
            setKatMsg({
              cmd: CMDS.SET_PREAMP_SOLO,
              data: !solo,
            });
          }}
        />
      </ButtonsLeft>
      <ButtonsCenter>
        {Object.keys(buttonsCenter).map((key) => {
          return (
            <KatButton
              key={key}
              caption={buttonsCenter[key].caption}
              active={buttonsCenter[key].value}
              onClick={() => {
                setKatMsg({
                  cmd: buttonsCenter[key].cmd,
                  data: !buttonsCenter[key].value,
                });
              }}
            />
          );
        })}
      </ButtonsCenter>
      <ButtonsRight>
        {Object.keys(channel < 5 ? chGroupA : chGroupB).map((key) => {
          return (
            <KatButton
              key={key}
              caption={channel < 5 ? chGroupA[key] : chGroupB[key]}
              active={Number(key) === channel}
              onClick={() => {
                setKatMsg({
                  cmd: CMDS.SET_CHANNEL,
                  data: Number(key),
                });
              }}
            />
          );
        })}
        <KatButton
          caption={'PANEL'}
          active={channel === 0}
          onClick={() => {
            setKatMsg({
              cmd: CMDS.SET_CHANNEL,
              data: 0,
            });
          }}
        />
      </ButtonsRight>
    </Wrapper>
  );
}
