import styled from 'styled-components';

import { RiCheckboxBlankCircleFill } from 'react-icons/ri';

export const Wrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.card.bg};
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  width: 100%;
  padding-top: ${(props) => (props.$compactCard ? '50%' : '100%')};
  margin: 0 auto;
  border-radius: 8px;
  transition: 0.2s;
  user-select: none;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    background-color: ${({ theme }) => theme.color.card.bgHover};
  }
`;

export const Content = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  justify-items: center;
  padding: 0 12px;
  font-size: ${(props) => (props.$compactCard ? '0.75rem' : '0.85rem')};
  text-align: center;
  color: ${({ theme }) => theme.color.card.fg};

  @media (max-width: ${({ theme }) => theme.break.horiz.sm}) {
    font-size: ${(props) => (props.$compactCard ? '0.55rem' : '0.65rem')};
  }
`;

export const Brand = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
  padding: 0 12px;

  @media (max-width: ${({ theme }) => theme.break.horiz.sm}) {
    font-size: 0.7rem;
  }
`;

export const Label = styled.div`
  background: ${(props) =>
    props.color ? props.color : ({ theme }) => theme.color.card.labelBg};
  display: flex;
  pointer-events: none;
  align-items: center;
  padding: 3px 3px;
  font-size: 0.7rem;
  color: ${(props) =>
    props.color ? 'white' : ({ theme }) => theme.color.card.labelFg};

  @media (max-width: ${({ theme }) => theme.break.horiz.sm}) {
    padding: 2px 2px;
    font-size: 0.6rem;
  }
`;

export const Footer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  gap: 4px;
  width: 100%;
  height: 20%;
  overflow: scroll;

  @media (max-width: ${({ theme }) => theme.break.horiz.sm}) {
    .flag {
      height: 1.05rem;
    }
  }
`;

export const IconAmp = styled.img`
  display: block;
  height: ${(props) => (props.$compactCard ? '46px' : '50px')};
  max-width: 70%;

  @media (max-width: ${({ theme }) => theme.break.horiz.sm}) {
    height: 42px;
  }
`;

export const Led = styled(RiCheckboxBlankCircleFill)`
  position: absolute;
  display: flex;
  align-items: center;
  top: 12px;
  right: 12px;
  color: ${(props) =>
    props.$active ? 'crimson' : ({ theme }) => theme.color.card.ledFg};

  @media (max-width: ${({ theme }) => theme.break.horiz.sm}) {
    top: 10px;
    right: 10px;
  }
`;
