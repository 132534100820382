import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  align-items: center;
`;

export const Button = styled.div`
  height: 20px;
  width: 44px;
  border-radius: 8px;
  background-color: var(--katGreyMedium);
  padding: 6px 10px;

  &:hover {
    background-color: dimgrey;
  }
`;

export const Led = styled.div`
  height: 8px;
  width: 24px;
  border-radius: 8px;
  background-color: ${(props) => (props.$active ? 'crimson' : '#484848')};
`;

export const Label = styled.div`
  font-size: 0.7rem;
  font-weight: 800;
  color: var(--katGreyLight);
  user-select: none;
`;
